<template>
    <div class="overall_situation">
        <template v-if="isEmpty">
            <div class="empty">
                <el-empty description="暂无报告"></el-empty>
            </div>
        </template>
        <template v-else>
            <h1 class="First_level_heading">1.整体情况</h1>
            <div class="participation">
                <div class="broad_heading flex-align-around">
                    <div class="Participate_assessment nature">
                        <div class="broad_title">本次测评参与</div>
                        <div class="broad_tcount">
                            <span>{{ userNum }}</span><span>人</span>
                        </div>
                        <div class="proportion">本年级参与率{{ gradeRate }}%</div>
                    </div>
                    <div class="hold_posture nature">
                        <div class="broad_title">优秀</div>
                        <div class="broad_tcount">
                            <span>{{ wrongGrip }}</span><span>人</span>
                        </div>
                        <div class="proportion">占本年级的{{ gradeWrongGrip }}%</div>
                    </div>
                    <div class="sit_posture nature">
                        <div class="broad_title">待提升</div>
                        <div class="broad_tcount">
                            <span>{{ wrongSitPosition }}</span><span>人</span>
                        </div>
                        <div class="proportion">本年级{{ gradeWrongSitPosition }}人</div>
                    </div>
                    <div class="excellent_rate nature">
                        <div class="broad_title">优良率</div>
                        <div class="broad_tcount">
                            <span>{{ excellentRate }}</span><span>%</span>
                        </div>
                        <div class="proportion">本年级{{ gradeExcellentRate }}%</div>
                    </div>
                </div>
                <div class="writing_habit_sitting">
                    <div class="flex-between">
                        <div>
                            <div class="Secondary_heading">
                                <div class="vertical_grain_icon"></div> <span class="title">本班单项成绩等级占比</span>
                            </div>
                            <div class="substance">
                                <div class="substance_details flex-between">
                                    <div class="sitting_position_table">
                                        <Short_form_tea :construction="week_construction" :tabledata="week_Data" type="月测">
                                        </Short_form_tea>
                                        <div class="sitting_position_graph" id="month_class_proportion"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="Secondary_heading">
                                <div class="vertical_grain_icon"></div> <span class="title">本班学生测评等级列表</span>
                            </div>
                            <div class="student_list_box2">
                                <div class="student_list2">
                                    <Short_form_tea :construction="weekstudent_list_construction" :high="HeightList"
                                        :row_high="HeightList_row" :tabledata="week_student_list_Data">
                                    </Short_form_tea>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="sitting_conclusion conclusion">
                        <span class="prefix_title">结论1</span>
                        本班学生综合成绩来看,平均成绩<span class="Comment_highlight">{{ maxClassLevel }}</span>等级的学生居多,
                        {{ '优良率' }}为<span class="Comment_highlight">{{ maxClassProp }}%</span>
                        <span v-if="maxgradeProp != 0 && maxgradeProp != 0">，
                            <span class="Comment_highlight">{{ gradeCompare }}</span>年级{{
                                '优良率' }}。
                        </span>
                        <span v-else>。</span>
                    </div>
                </div>
                <singleSubjectAvg_tea_month_new ref="singleSubjectAvg_tea_month_new" :userNum="userNum"
                    :gradeValue="gradeValue" type="周测">
                </singleSubjectAvg_tea_month_new>
                <div class="writing_habit_sitting" v-if="workList.length != 0">
                    <div class="outstanding_example">
                        <div class="outstanding_tit flex-align-center">
                            <img src="../../assets/newReport/specimen2.png" alt="" />
                        </div>
                        <div class="works flex-align-center">
                            <div class="el_image_box flex-align-center">
                                <el-image :src="workurl"></el-image>
                            </div>
                        </div>
                        <div class="changeset flex-align-center ">
                            <div class="changeset_btn flex-align-center" @click="stochastic">换一组查看</div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import singleSubjectAvg_tea_month_new from './singleSubjectAvg_tea_month_new.vue'
import Short_form_tea from './appraisalCharts/Short_form_tea.vue'
export default {
    //import引入组件才能使用
    components: {
        Short_form_tea,
        singleSubjectAvg_tea_month_new
    },
    data() {
        return {
            //测评人数
            userNum: 0,
            //本年级参与率
            gradeRate: 0,
            //握姿错误
            wrongGrip: 0,
            //本年级握姿错误
            gradeWrongGrip: 0,
            //坐姿错误
            wrongSitPosition: 0,
            //本年级坐姿错误
            gradeWrongSitPosition: 0,
            //优秀率
            excellentRate: 0,
            //本年级优秀率
            gradeExcellentRate: 0,
            // 月测-本班单项成绩等级占比表
            week_construction: [{
                fieldName: 'integrationLevel',
                title: '等级'
            },
            {
                fieldName: 'classPropRate',
                title: '本班人数占比'
            },
            {
                fieldName: 'gradePropRate',
                title: '年级平均人数占比'
            }],
            week_Data: [],
            weekstudent_list_construction: [{
                fieldName: 'className',
                title: '班级'
            }, {
                fieldName: 'stuName',
                title: '姓名'
            },
            {
                fieldName: 'levelDesc',
                title: '等级'
            }],
            week_student_list_Data: [],
            workList: [],
            workurl: '',
            HeightList: '635.7px',
            HeightList_row: '42.38px',
            maxClassLevel: '',
            maxClassProp: 0,
            maxgradeProp: 0,
            gradeCompare: '',
            AI_Data: [],
            termValue: null,
            weekValue: null,
            weekName: null,
            gradeId: null,
            gradeValue: null,
            isEmpty: null,

        };
    },
    // 计算属性
    computed: {},
    // 监听data中的数据变化
    watch: {},
    // 生命周期：挂载完成时（可以访问DOM元素）
    mounted() {

    },
    // 方法集合
    methods: {
        async Request_collection(termValue, weekValue, weekName, gradeId, gradeValue, isEmpty) {
            this.termValue = termValue;
            this.weekValue = weekValue;
            this.weekName = weekName;
            this.gradeId = gradeId;
            this.gradeValue = gradeValue;
            this.isEmpty = isEmpty;
            // 整体数据
            await this.getparticipation();
            // 本班单项成绩等级占比
            await this.monthLevelStuNumGroup();
            // 本班学生测评等级列表
            await this.monthClassStuScoreList();
            // 获取测评图例
            await this.getlevel4WorkPage();
            // AI测评数据
            await this.monthAI();
            // 学生等级比例对比
            await this.getstudentLvRatioCompareTV2_month();
        },

        // 整体数据
        async getparticipation() {
            let data = {
                schoolId: this.$route.query.schoolId,
                termKey: this.termValue,
                testId: this.weekValue,
                examType: 5,
                classId: this.gradeId,
            };
            let resData = await this.$Api.Report.getoverallSituation_month(data);
            this.userNum = resData.data.classTakeNum;
            this.gradeRate = resData.data.gradeJoinRate;
            this.wrongGrip = resData.data.goodP;
            this.gradeWrongGrip = resData.data.gradeGoodPRateClass;
            this.wrongSitPosition = resData.data.bad;
            this.gradeWrongSitPosition = resData.data.gradeBad;
            this.excellentRate = resData.data.goodRate;
            this.gradeExcellentRate = resData.data.gradeGoodRate;
        },
        // 本班单项成绩等级占比
        async monthLevelStuNumGroup() {
            let data = {
                schoolId: this.$route.query.schoolId,
                termKey: this.termValue,
                testId: this.weekValue,
                examType: 5,
                classId: this.gradeId,
            }
            let resData = await this.$Api.Report.getlevelStuNumGroupV2_month(data);
            resData.data.map(item => {
                item.classPropRate = item.classProp + '%';
                item.gradePropRate = item.gradeProp + '%';
                item.integrationLevel = item.integrationLevel == 1 ? '待提升' : item.integrationLevel == 2 ? '合格' : item.integrationLevel == 3 ? '良好' : '优秀'
            })
            this.week_Data = resData.data.reverse();
            let maxClassProp = 0;
            let maxClassProp2 = 0;
            let maxClassLevel = null;
            let gradeProp = 0;
            let arr = []
            this.week_Data.map((item, index) => {
                let obj = {
                    value: item.classProp,
                    name: item.integrationLevel
                }
                arr.push(obj);
                if (item.classProp > 0) {
                    if (item.classProp > maxClassProp) {
                        maxClassProp = item.classProp;
                        maxClassLevel = item.integrationLevel;
                    } else if (item.classProp == maxClassProp) {
                        maxClassLevel += item.integrationLevel
                    }
                };
                if (item.integrationLevel == '良好' || item.integrationLevel == '优秀') {
                    maxClassProp2 += item.classProp;
                    gradeProp += item.gradeProp;
                }

            })
            this.maxClassProp = maxClassProp2;
            this.maxgradeProp = gradeProp;
            this.maxClassLevel = maxClassLevel;
            this.gradeCompare = maxClassProp2 > gradeProp ? '高于' : maxClassProp2 < gradeProp ? '低于' : '等于';
            this.month_class_proportion_graph(arr);
        },
        // 本班单项成绩等级占比饼图
        month_class_proportion_graph(data) {
            this.$echarts.init(document.getElementById("month_class_proportion")).dispose();
            let myChart = this.$echarts.init(
                document.getElementById("month_class_proportion")
            );
            myChart.setOption({
                // color: ['#48c5ec', '#72d07e'],
                legend: {
                    top: '8%',
                    left: 'center',
                    itemWidth: this.graphicRatio(22),
                    itemHeight: this.graphicRatio(14),
                    textStyle: {
                        fontSize: this.graphicRatio(12),
                    }
                },
                series: [
                    {
                        // name: '书写习惯-坐姿',
                        type: 'pie',
                        radius: '50%',
                        label: {
                            formatter: "{b} {c} %",
                            fontSize: this.graphicRatio(14),
                        },
                        data,
                        itemStyle: {
                            borderWidth: this.graphicRatio(5),
                            borderColor: '#fff',
                        }
                    }
                ]
            });
            window.addEventListener("resize", () => {
                myChart.resize();
            });
        },
        // 本班学生测评等级列表
        async monthClassStuScoreList() {
            let data = {
                schoolId: this.$route.query.schoolId,
                termKey: this.termValue,
                testId: this.weekValue,
                examType: 5,
                classId: this.gradeId,
            }
            let resData = await this.$Api.Report.getclassStuScoreListV2_month(data);
            this.week_student_list_Data = resData.data;
        },
        //测评图片
        async getlevel4WorkPage() {
            this.workList = [];
            let data = {
                schoolId: this.$route.query.schoolId,
                termKey: this.termValue,
                testId: this.weekValue,
                examType: 5,
                classId: this.gradeId,
            }
            let resData = await this.$Api.Report.getWorksSchV2(data);
            resData.data.records.map(item => {
                this.workList.push(item.filePath)
            });
            this.workurl = this.workList[0];
        },
        //随机切换图片
        stochastic() {
            const randomIndex = Math.floor(Math.random() * this.workList.length);
            this.workurl = this.workList[randomIndex];
        },
        //ai测评数据
        async monthAI() {
            let data = {
                schoolId: this.$route.query.schoolId,
                termKey: this.termValue,
                testId: this.weekValue,
                examType: 5,
                classId: this.gradeId,
            }
            let resData = await this.$Api.Report.getaiScoreV2_month(data);
            if (resData.data.class.length == 0 || !resData.data.class[0]) {
                this.$refs.singleSubjectAvg_tea_month_new.receiveData(this.AI_Data, [0, 0, 0, 0, 0, 0, 0, 0], 100)
            } else {
                let data1 = resData.data.class[0];
                data1.name = this.gradeValue;
                data1.centerPos_type = this.judge_type_icon(resData.data.class[0].centerPos, resData.data.grade[0].centerPos);
                data1.gridSize_type = this.judge_type_icon(resData.data.class[0].gridSize, resData.data.grade[0].gridSize);
                data1.glyphGrav_type = this.judge_type_icon(resData.data.class[0].glyphGrav, resData.data.grade[0].glyphGrav);
                data1.comboRel_type = this.judge_type_icon(resData.data.class[0].comboRel, resData.data.grade[0].comboRel);
                data1.rewindRel_type = this.judge_type_icon(resData.data.class[0].rewindRel, resData.data.grade[0].rewindRel);
                data1.multiDist_type = this.judge_type_icon(resData.data.class[0].multiDist, resData.data.grade[0].multiDist);
                data1.strokeRel_type = this.judge_type_icon(resData.data.class[0].strokeRel, resData.data.grade[0].strokeRel);
                data1.integrity_type = this.judge_type_icon(resData.data.class[0].integrity, resData.data.grade[0].integrity);

                data1.centerPos_average = this.average(resData.data.class[0].centerPos, resData.data.grade[0].centerPos);
                data1.gridSize_average = this.average(resData.data.class[0].gridSize, resData.data.grade[0].gridSize);
                data1.glyphGrav_average = this.average(resData.data.class[0].glyphGrav, resData.data.grade[0].glyphGrav);
                data1.comboRel_average = this.average(resData.data.class[0].comboRel, resData.data.grade[0].comboRel);
                data1.rewindRel_average = this.average(resData.data.class[0].rewindRel, resData.data.grade[0].rewindRel);
                data1.multiDist_average = this.average(resData.data.class[0].multiDist, resData.data.grade[0].multiDist);
                data1.strokeRel_average = this.average(resData.data.class[0].strokeRel, resData.data.grade[0].strokeRel);
                data1.integrity_average = this.average(resData.data.class[0].integrity, resData.data.grade[0].integrity);
                data1.centerPos_average = this.average(resData.data.class[0].centerPos, resData.data.grade[0].centerPos);

                data1.centerPos_level = this.judgeLevel(resData.data.class[0].centerPos);
                data1.gridSize_level = this.judgeLevel(resData.data.class[0].gridSize);
                data1.glyphGrav_level = this.judgeLevel(resData.data.class[0].glyphGrav);
                data1.comboRel_level = this.judgeLevel(resData.data.class[0].comboRel);
                data1.rewindRel_level = this.judgeLevel(resData.data.class[0].rewindRel);
                data1.multiDist_level = this.judgeLevel(resData.data.class[0].multiDist);
                data1.strokeRel_level = this.judgeLevel(resData.data.class[0].strokeRel);
                data1.integrity_level = this.judgeLevel(resData.data.class[0].integrity);
                let data2 = resData.data.grade[0];
                data2.name = '年级均值';
                data2.centerPos_type = '';
                data2.gridSize_type = '';
                data2.glyphGrav_type = '';
                data2.comboRel_type = '';
                data2.rewindRel_type = '';
                data2.multiDist_type = '';
                data2.strokeRel_type = '';
                data2.integrity_type = '';
                this.AI_Data = [data1, data2];
                let arr = [
                    resData.data.class[0].centerPos,
                    resData.data.class[0].gridSize,
                    resData.data.class[0].glyphGrav,
                    resData.data.class[0].comboRel,
                    resData.data.class[0].rewindRel,
                    resData.data.class[0].multiDist,
                    resData.data.class[0].strokeRel,
                    resData.data.class[0].integrity
                ]
                this.$refs.singleSubjectAvg_tea_month_new.receiveData(this.AI_Data, arr, 100)
            }
        },
        judge_type_icon(value1, value2) {
            if (value1 > value2) {
                return 'imgUrl1'
            } else if (value1 < value2) {
                return 'imgUrl2'
            } else {
                return 'imgUrl3'
            }

        },
        average(value1, value2) {
            return value1 > value2 ? '高于' : value1 < value2 ? '低于' : '等于'
        },
        //判断水平
        judgeLevel(value) {
            if (this.table_type == 1) {
                if (value >= 90) {
                    return '优秀+'
                } else if (value >= 80 && value < 90) {
                    return '优秀-'
                } else if (value >= 60 && value < 80) {
                    return '良好'
                } else {
                    return '待提升'
                }
            } else {
                if (value >= 85) {
                    return '优秀';
                } else if (value >= 70 && value < 85) {
                    return '良好';
                } else if (value >= 60 && value < 70) {
                    return '合格';
                } else {
                    return '待提升';
                }
            }

        },
        async getstudentLvRatioCompareTV2_month() {
            let data = {
                schoolId: this.$route.query.schoolId,
                termKey: this.termValue,
                testId: this.weekValue,
                examType: 5,
                classId: this.gradeId,
            };
            let res = await this.$Api.Report.getstudentLvRatioCompareTV2_month(data);
            // console.log('++++++++++++++', res);

            this.$refs.singleSubjectAvg_tea_month_new.gradesPercentage(res);
        }
    },

}
</script>
<style lang='less' scoped>
@import url("./less/frontBackaAppraisal_tea.less");
</style>