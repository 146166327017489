import { render, staticRenderFns } from "./weekTest_tea.vue?vue&type=template&id=736c2221&scoped=true"
import script from "./weekTest_tea.vue?vue&type=script&lang=js"
export * from "./weekTest_tea.vue?vue&type=script&lang=js"
import style0 from "./weekTest_tea.vue?vue&type=style&index=0&id=736c2221&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "736c2221",
  null
  
)

export default component.exports