<template>
    <div>
        <div class="writing_habit_sitting">
            <div class="Secondary_heading">
                <div class="vertical_grain_icon"></div> <span class="title">本班单项成绩等级占比</span>
            </div>
            <div class="grade_proportion">
                <el-table :data="hold_ratio_Data" style="width: 100%" :stripe="true" empty-text="暂无数据"
                    :row-style="{ height: '60px' }" :header-row-style="{ height: '60px' }">
                    <!-- <el-table-column :label="gradeValue + '（' + userNum + '人）单项成绩各等级占比'" align="center"> -->
                    <el-table-column :label="gradeValue + ' 单项成绩各等级占比'" align="center">
                        <el-table-column prop="grade" label="等级" align="center" width="109px">
                        </el-table-column>
                        <el-table-column label="人数占比" align="center" width="109px">
                            <template slot-scope="scope">
                                占比（%）
                            </template>
                        </el-table-column>
                        <el-table-column label="章法占格" align="center">
                            <el-table-column prop="layout_1" label="卷面整洁" align="center" :formatter="formatCleanliness">
                            </el-table-column>
                            <el-table-column prop="layout_2" label="占格占位" align="center" :formatter="formatCleanliness">
                            </el-table-column>
                            <el-table-column prop="layout_3" label="字形大小" align="center" :formatter="formatCleanliness">
                            </el-table-column>
                        </el-table-column>
                        <el-table-column label="结构组合" align="center">
                            <el-table-column prop="structure_1" label="字形重点" align="center"
                                :formatter="formatCleanliness"></el-table-column>
                            <el-table-column prop="structure_2" label="收放关系" align="center"
                                :formatter="formatCleanliness"></el-table-column>
                            <el-table-column prop="structure_3" label="笔画关系" align="center"
                                :formatter="formatCleanliness"></el-table-column>
                            <el-table-column prop="structure_4" label="组合关系" align="center"
                                :formatter="formatCleanliness"></el-table-column>
                        </el-table-column>
                        <el-table-column label="结构组合" align="center">
                            <el-table-column prop="form_1" label="方向" align="center"
                                :formatter="formatCleanliness"></el-table-column>
                            <el-table-column prop="form_2" label="笔性" align="center"
                                :formatter="formatCleanliness"></el-table-column>
                            <el-table-column prop="form_3" label="粗细" align="center"
                                :formatter="formatCleanliness"></el-table-column>
                        </el-table-column>
                        <el-table-column prop="complete_1" label="试卷完成度" align="center"
                            :formatter="formatCleanliness"></el-table-column>
                        <el-table-column prop="totalRatio" label="综合占比" align="center" :formatter="formatCleanliness">
                        </el-table-column>
                    </el-table-column>
                </el-table>
            </div>
            <div class="Term_comparison flex-between">
                <div class="Term_comparison_table">
                    <div class="prevSemGradeComp">与上学期等级对比</div>
                    <el-table :data="Term_comparison_Data" :row-style="{ height: row_height1 }"
                        :header-row-style="{ height: row_height1 }" empty-text="暂无数据">
                        <el-table-column prop="date" label="学期" align="center">
                            <template slot-scope="scope">
                                <span style="margin-left: 10px">{{ scope.row.date }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="level4" label="优秀+" align="center">
                            <template slot-scope="scope">
                                <div class="imgUrlbox">
                                    <img v-if="scope.row.level1.num != '0%'" :class="scope.row.level1.type"
                                        :src="imgUrl[scope.row.level1.type]" alt="">
                                </div>
                                <span style="margin-left: 10px">{{ scope.row.level1.num == '0%' ? '/' :
                                    scope.row.level1.num }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="level3" label="优秀" align="center">
                            <template slot-scope="scope">
                                <div class="imgUrlbox"> <img v-if="scope.row.level2.num != '0%'"
                                        :class="scope.row.level2.type" :src="imgUrl[scope.row.level2.type]" alt="">
                                </div>
                                <span style="margin-left: 10px">{{ scope.row.level2.num == '0%' ? '/' :
                                    scope.row.level2.num }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="level2" label="良好" align="center">
                            <template slot-scope="scope">
                                <div class="imgUrlbox"> <img v-if="scope.row.level3.num != '0%'"
                                        :class="scope.row.level3.type" :src="imgUrl[scope.row.level3.type]" alt="">
                                </div>
                                <span style="margin-left: 10px">{{ scope.row.level3.num == '0%' ? '/' :
                                    scope.row.level3.num }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="level1" label="待提升" align="center">
                            <template slot-scope="scope">
                                <div class="imgUrlbox"> <img v-if="scope.row.level4.num != '0%'"
                                        :class="scope.row.level3.type" :src="imgUrl[scope.row.level3.type]" alt="">
                                </div>
                                <span style="margin-left: 10px">{{ scope.row.level4.num == '0%' ? '/' :
                                    scope.row.level4.num }}</span>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <div class="Term_comparison_graph" id="Term_comparison_graph"></div>
            </div>
            <div class="sitting_conclusion conclusion">
                <span class="prefix_title">结论3</span>
                本班学生综合成绩来看，平均成绩<span class="Comment_highlight">{{ k1 }}</span>等级的学生居多。优秀+&优秀共
                <span class="Comment_highlight">{{ k2 }}%</span>，相对上学期有所<span class="Comment_highlight">{{ k3 }}</span>。
                <p>(1)、<span class="Comment_highlight">章法占格</span>上，较多学生在{{ layout }}</p>
                <p>(2)、<span class="Comment_highlight">结构组合</span>上，较多学生在{{ structure }}</p>
                <p>(3)、<span class="Comment_highlight">笔画形态</span>上，较多学生在{{ form }}</p>
                <p>(4)、<span class="Comment_highlight">作品完整度</span>上，较多学生在{{ complete }}</p>
            </div>
        </div>
        <div class="writing_habit_sitting">
            <div class="Secondary_heading">
                <div class="vertical_grain_icon"></div> <span class="title">一级维度成绩</span>
            </div>
            <div class="Term_comparison flex-between">
                <div class="Term_comparison_table">
                    <el-table :data="dimension_Data" style="width: 100%" :stripe="true" :row-style="{ height: '45px' }"
                        :header-row-style="{ height: '45px' }" empty-text="暂无数据">
                        <el-table-column prop="name" label="一级维度" align="center">
                            <template slot-scope="scope">
                                <span style="margin-left: 10px">{{ scope.row.name }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="layout" label="章法占格" align="center">
                            <template slot-scope="scope">
                                <div class="imgUrlbox">
                                    <img :class="scope.row.layout.type" :src="imgUrl[scope.row.layout.type]" alt="">
                                </div>
                                <span style="margin-left: 10px">{{ scope.row.layout.num }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="structure" label="结构组合" align="center">
                            <template slot-scope="scope">
                                <div class="imgUrlbox"> <img :class="scope.row.structure.type"
                                        :src="imgUrl[scope.row.structure.type]" alt=""></div>
                                <span style="margin-left: 10px">{{ scope.row.structure.num }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="form" label="笔画形态" align="center">
                            <template slot-scope="scope">
                                <div class="imgUrlbox"> <img :class="scope.row.form.type" :src="imgUrl[scope.row.form.type]"
                                        alt=""></div>
                                <span style="margin-left: 10px">{{ scope.row.form.num }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="complete" label="作品完整度" align="center">
                            <template slot-scope="scope">
                                <div class="imgUrlbox"> <img :class="scope.row.complete.type"
                                        :src="imgUrl[scope.row.complete.type]" alt=""></div>
                                <span style="margin-left: 10px">{{ scope.row.complete.num }}</span>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <div class="dimension_graph" id="dimension_graph"></div>
            </div>
            <div class="sitting_conclusion conclusion">
                <span class="prefix_title">结论4</span>
                <p>(1)、章法占格维度：成绩处于<span class="Comment_highlight">{{ dimension_Data_layout1 }}</span>水平，<span
                        class="Comment_highlight">{{ dimension_Data_layout2 }}</span>年级平均值。
                </p>
                <p>(2)、结构组合维度：成绩处于<span class="Comment_highlight">{{ dimension_Data_structure1 }}</span>水平，<span
                        class="Comment_highlight">{{ dimension_Data_structure2 }}</span>年级平均值。
                </p>
                <p>(3)、笔画形态维度：成绩处于<span class="Comment_highlight">{{ dimension_Data_form1 }}</span>水平，<span
                        class="Comment_highlight">{{ dimension_Data_form2 }}</span>年级平均值。
                </p>
                <p>(4)、作品完整度：成绩处于<span class="Comment_highlight">{{ dimension_Data_complete1 }}</span>水平，<span
                        class="Comment_highlight">{{ dimension_Data_complete2 }}</span>年级平均值。
                </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    //import引入组件才能使用
    components: {},
    // props: ['gradeId', 'termValue', 'reportType', 'reportCode', 'gradeValue_pass', 'userNum_pass'],
    data() {
        return {
            // 本班单项成绩等级占比
            hold_ratio_Data: [],
            //年级
            gradeValue: '',
            //测评人数
            userNum: 0,
            Term_comparison_Data: [],
            row_height1: '45px',
            k1: '',
            k2: '',
            k3: '',
            // 章法占格
            layout: '',
            // 结构组合
            structure: '',
            // 笔画形态
            form: '',
            // 作品完整度
            complete: '',
            dimension_Data: [],
            dimension_Data_layout1: '',
            dimension_Data_layout2: '',
            dimension_Data_structure1: '',
            dimension_Data_structure2: '',
            dimension_Data_form1: '',
            dimension_Data_form2: '',
            dimension_Data_complete1: '',
            dimension_Data_complete2: '',
            //箭头类型
            imgUrl: {
                imgUrl1: require('@/assets/newReport/greent.png'),
                imgUrl2: require('@/assets/newReport/redx.png'),
                imgUrl3: require('@/assets/newReport/or_r.png'),
            },

        };
    },
    // 计算属性
    computed: {},
    // 监听data中的数据变化
    watch: {},
    // 方法集合
    methods: {
        //接收父级传值 --本班单项成绩等级占比
        receiveData(resData, gradeData, peopleNum, table_type) {
            this.gradeValue = gradeData;
            this.userNum = peopleNum;
            this.hold_ratio_Data = resData.data.resultList;
            this.hold_ratio_Data[0].grade = '优秀+';
            this.hold_ratio_Data[1].grade = '优秀-';
            this.hold_ratio_Data[2].grade = '良好';
            this.hold_ratio_Data[3].grade = '待提升';
            this.layout = resData.data.layout;
            this.structure = resData.data.structure;
            this.form = resData.data.form;
            this.complete = resData.data.complete;
            this.k1 = resData.data.k1;
            this.k2 = resData.data.k2;
            this.k3 = resData.data.k3;
            // 与上学期等级对比
            let semester_this_compare = {
                date: '本学期',
                level1: {
                    type: this.judge_type_icon(resData.data.compare[0].level4, resData.data.compare[1].level4),
                    num: resData.data.compare[0].level4 + '%',
                },
                level2: {
                    type: this.judge_type_icon(resData.data.compare[0].level3, resData.data.compare[1].level3),
                    num: resData.data.compare[0].level3 + '%',
                },
                level3: {
                    type: this.judge_type_icon(resData.data.compare[0].level2, resData.data.compare[1].level2),
                    num: resData.data.compare[0].level2 + '%',
                },
                level4: {
                    type: this.judge_type_icon(resData.data.compare[0].level1, resData.data.compare[1].level1),
                    num: resData.data.compare[0].level1 + '%',
                },
            };
            let semester_last_compare = {
                date: '上学期',
                level1: {
                    type: '',
                    num: resData.data.compare[1].level4 + '%',
                },
                level2: {
                    type: '',
                    num: resData.data.compare[1].level3 + '%',
                },
                level3: {
                    type: '',
                    num: resData.data.compare[1].level2 + '%',
                },
                level4: {
                    type: '',
                    num: resData.data.compare[1].level1 + '%',
                },
            };
            this.Term_comparison_Data = [semester_this_compare, semester_last_compare];
            //图例
            let semester_this = Object.values(resData.data.compare[0]).reverse();
            this.Term_comparison_graph(semester_this, table_type)
        },
        judge_type_icon(value1, value2) {
            if (value1 > value2) {
                return 'imgUrl1'
            } else if (value1 < value2) {
                return 'imgUrl2'
            } else {
                return 'imgUrl3'
            }

        },
        //与上学期对比
        Term_comparison_graph(value1, table_type) {

            this.$echarts.init(document.getElementById("Term_comparison_graph")).dispose();
            let myChart = this.$echarts.init(
                document.getElementById("Term_comparison_graph")
            );
            myChart.setOption({
                grid: {
                    top: '30%',
                    left: '4%',
                    right: '4%',
                    bottom: '2%',
                    containLabel: true,
                },
                tooltip: {
                    trigger: 'axis',
                    confine: true,
                    padding: this.graphicRatio(5),
                    textStyle: {
                        fontSize: this.graphicRatio(14),
                    },
                },
                legend: {
                    show: true,
                    icon: 'rect',
                    orient: 'horizontal',
                    top: '6%',
                    y: 'center',
                    itemWidth: this.graphicRatio(22),
                    itemHeight: this.graphicRatio(14),
                    textStyle: {
                        fontSize: this.graphicRatio(12),
                    },
                    data: [
                        { name: '本学期', icon: 'circle' },
                    ],
                },
                xAxis: [
                    {
                        splitLine: {
                            show: false,
                        },
                        axisTick: {
                            show: true,
                            lineStyle: {
                                color: '#dddddd'
                            },
                        },
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: '#dddddd'
                            },
                        },
                        axisLabel: {
                            show: true,
                            fontSize: this.graphicRatio(14),
                            color: '#555555',
                            // interval: 'auto',
                        },
                        type: 'category',
                        data: table_type == 1 ? ["优秀+", "优秀-", "良好", "待提升"] : ["优秀", "良好", "合格", "待提升"],
                    },
                ],
                yAxis: [
                    {
                        type: 'value',
                        axisLabel: {
                            show: false,
                        },
                        axisLine: {
                            show: false,
                        },
                        splitLine: {
                            show: false,
                        },
                        axisTick: {
                            show: false,
                        },
                    },
                    {
                        axisLabel: {
                            show: false,
                        },
                        axisLine: {
                            show: false,
                        },
                        splitLine: {
                            show: false,
                        },
                        axisTick: {
                            show: false,
                        },
                    },
                ],
                series: [
                    {
                        name: '本学期',
                        type: 'pictorialBar',
                        barGap: '0%',
                        barWidth: this.graphicRatio(60),
                        symbol: "path://M12.000,-0.000 C12.000,-0.000 16.074,60.121 22.731,60.121 C26.173,60.121 -3.234,60.121 0.511,60.121 C7.072,60.121 12.000,-0.000 12.000,-0.000 Z",
                        // barGap:10,
                        color: '#247FFF',
                        label: {
                            show: true,
                            position: 'top',
                            fontSize: this.graphicRatio(14),
                            formatter: function (params) {
                                return params.value + '%'
                            },
                        },
                        data: value1,
                        itemStyle: {
                            color: new this.$echarts.graphic.LinearGradient(
                                0,
                                1,
                                0,
                                0,
                                [
                                    {
                                        offset: 0,
                                        color: 'rgba(49, 232, 255, 0.68)', // 0% 处的颜色
                                    },
                                    {
                                        offset: 0.4,
                                        color: 'rgba(149, 231, 212, 1)', // 100% 处的颜色
                                    },
                                ],
                                false
                            ),
                        },
                        emphasis: {
                            disabled: true,
                            focus: 'none',
                        },
                    },
                ],
            });
            window.addEventListener("resize", () => {
                myChart.resize();
            });
        },
        //判断空数据（本班单项成绩等级占比）
        formatCleanliness(row, column, cellValue, index) {
            return cellValue == 0 ? '/' : cellValue + '%';
        },
        // 接收父级传值 --一级维度成绩
        receiveData_dimension(resData) {
            if (resData.data?.classList.length == 0) {
                this.dimension_graph([0, 0, 0, 0], 100);
                this.dimension_Data = [];
                return;
            }
            let class_new_data = {};
            let class_average_data = {};
            resData.data.classList.forEach((item, index) => {
                if (item.parentId == 1) {
                    class_new_data.name = this.gradeValue;
                    class_new_data.layout = {
                        type: '',
                        num: item.average,
                    }
                } else if (item.parentId == 2) {
                    class_new_data.structure = {
                        type: '',
                        num: item.average,
                    }
                } else if (item.parentId == 3) {
                    class_new_data.form = {
                        type: '',
                        num: item.average,
                    }
                } else if (item.parentId == 4) {
                    class_new_data.complete = {
                        type: '',
                        num: item.average,
                    }
                }
            });
            resData.data.grade.forEach((item, index) => {
                if (item.parentId == 1) {
                    class_average_data.name = '年级均值';
                    class_average_data.layout = {
                        type: '',
                        num: item.average,
                    }
                } else if (item.parentId == 2) {
                    class_average_data.structure = {
                        type: '',
                        num: item.average,
                    }
                } else if (item.parentId == 3) {
                    class_average_data.form = {
                        type: '',
                        num: item.average,
                    }
                } else if (item.parentId == 4) {
                    class_average_data.complete = {
                        type: '',
                        num: item.average,
                    }
                }
            });
            this.dimension_Data = [class_new_data, class_average_data];
            //icon
            this.dimension_Data[0].layout.type = this.judge_type_icon(this.dimension_Data[0].layout.num, this.dimension_Data[1].layout.num);
            this.dimension_Data[0].structure.type = this.judge_type_icon(this.dimension_Data[0].structure.num, this.dimension_Data[1].structure.num);
            this.dimension_Data[0].form.type = this.judge_type_icon(this.dimension_Data[0].form.num, this.dimension_Data[1].form.num);
            this.dimension_Data[0].complete.type = this.judge_type_icon(this.dimension_Data[0].complete.num, this.dimension_Data[1].complete.num);
            //评论
            this.dimension_Data_layout1 = this.judgeLevel(this.dimension_Data[0].layout.num);
            this.dimension_Data_layout2 = this.average(this.dimension_Data[0].layout.num, this.dimension_Data[1].layout.num);
            this.dimension_Data_structure1 = this.judgeLevel(this.dimension_Data[0].structure.num);
            this.dimension_Data_structure2 = this.average(this.dimension_Data[0].structure.num, this.dimension_Data[1].structure.num);
            this.dimension_Data_form1 = this.judgeLevel(this.dimension_Data[0].form.num);
            this.dimension_Data_form2 = this.average(this.dimension_Data[0].form.num, this.dimension_Data[1].form.num);
            this.dimension_Data_complete1 = this.judgeLevel(this.dimension_Data[0].complete.num);
            this.dimension_Data_complete2 = this.average(this.dimension_Data[0].complete.num, this.dimension_Data[1].complete.num);
            //图例
            let newarr = [
                Number(class_new_data.layout.num).toFixed(2),
                Number(class_new_data.structure.num).toFixed(2),
                Number(class_new_data.form.num).toFixed(2),
                Number(class_new_data.complete.num).toFixed(2)];
            // let maxValue = Math.max(...newarr);
            let maxValue = 100;
            this.dimension_graph(newarr, maxValue);
        },
        //判断水平
        judgeLevel(value) {
            if (value >= 90) {
                return '优秀+'
            } else if (value >= 80 && value < 90) {
                return '优秀-'
            } else if (value >= 60 && value < 80) {
                return '良好'
            } else {
                return '待提升'
            }
        },
        average(value1, value2) {
            return value1 > value2 ? '高于' : value1 < value2 ? '低于' : '等于'
        },
        //一级维度成绩
        dimension_graph(value, maxValue) {
            this.$echarts.init(document.getElementById("dimension_graph")).dispose();
            let myChart = this.$echarts.init(
                document.getElementById("dimension_graph")
            );
            let splitColor = "#fff";
            myChart.setOption({
                tooltip: {
                    show: true,
                    showContent: true,
                    textStyle: {
                        fontSize: this.graphicRatio(12),
                    },
                },
                radar: {
                    center: ["50%", "50%"], // 外圆的位置
                    radius: "60%",
                    axisName: {
                        color: "#033333",
                        fontSize: this.graphicRatio(14),
                        fontWeight: 400,
                        fontFamily: "PingFangSC-Regular,PingFang SC",
                        fontStyle: "normal",
                    },
                    indicator: [{ name: '章法占格', max: maxValue },
                    { name: '结构组合', max: maxValue },
                    { name: '笔画形态', max: maxValue },
                    { name: '作品完整度', max: maxValue },],
                    splitArea: {
                        // 坐标轴在 grid 区域中的分隔区域，默认不显示。
                        show: true,
                        areaStyle: {
                            // 分隔区域的样式设置。
                            color: ["RGBA(228, 229, 235, 1)"], // 分隔区域颜色。分隔区域会按数组中颜色的顺序依次循环设置颜色。默认是一个深浅的间隔色。
                        },
                    },
                    axisLine: {
                        // 指向外圈文本的分隔线样式
                        lineStyle: {
                            color: splitColor,
                        },
                    },
                    splitLine: {
                        lineStyle: {
                            type: "solid",
                            color: splitColor, // 分隔线颜色
                            width: this.graphicRatio(1), // 分隔线线宽
                        },
                    },
                },
                series: [{
                    type: "radar",
                    symbolSize: this.graphicRatio(10),
                    symbol: "none",
                    itemStyle: {
                        borderColor: "FFD900",
                        // color: "#fff",
                        borderWidth: this.graphicRatio(0.2),
                    },
                    lineStyle: {
                        width: this.graphicRatio(1),
                        color: '#9b95f7',
                    },
                    data: [{
                        name: '前侧',
                        value: value,
                        label: {
                            fontSize: this.graphicRatio(14),
                            formatter: '{value}%'
                        },
                        areaStyle: {
                            color: "rgba(155, 149, 247, 1)"
                        },
                    },],
                },
                ],

            });
            window.addEventListener("resize", () => {
                myChart.resize();
            });
        },
    },
    // 生命周期，创建完成时（可以访问当前this实例）
    created() {

    },
    // 生命周期：挂载完成时（可以访问DOM元素）
    mounted() {

    },
}
</script>
<style lang='less' scoped>
@import url("./less/frontBackaAppraisal_tea.less");
</style>